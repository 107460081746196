import { Button, Container, SizableText, XStack, XStackProps, isWeb } from '@my/ui'
import React from 'react'
import { ChevronLeft } from '@tamagui/lucide-icons'
import { useRouter } from 'solito/router'

interface NativePageNavigationProps {
  title?: string
  Controls?: React.ReactNode[] | React.ReactNode
  controlsWrapperProps?: XStackProps
}

export const NativePageNavigation = ({
  title,
  Controls,
  controlsWrapperProps,
}: NativePageNavigationProps) => {
  const { back } = useRouter()

  if (isWeb) return null

  return (
    <Container
      gutter
      fd="row"
      jc="space-between"
      ai="center"
      bg="$white"
      h={60}
      py="$2"
      zIndex="$4"
      gap="$3.5"
      size="xl"
    >
      <XStack x="$-2" gap="$2" ai="center" flex={1}>
        <Button
          size="$4"
          px="$1.5"
          plain
          icon={<ChevronLeft size={28} />}
          onPress={back}
        />
        <SizableText numberOfLines={1} size="$4" fow="600">
          {title}
        </SizableText>
      </XStack>
      <XStack ai="center" gap="$1" pl="$5" {...controlsWrapperProps}>
        {Controls}
      </XStack>
    </Container>
  )
}
