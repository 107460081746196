import {
  Container,
  SizableText,
  useMedia,
  YStack,
  Paragraph,
  Button,
  SmartLink,
  Stack,
  isWeb,
} from '@my/ui'
import { MainLayout, Meta, NativePageNavigation } from 'app/components'
import { SolitoImage } from 'solito/image'
import ImageSrc from 'app/assets/images/404.svg'

export const ErrorScreen404 = () => {
  const media = useMedia()

  return (
    <MainLayout minHeight={isWeb ? '100vh' : '100%'}>
      <Meta title="404: Page not found" />
      <NativePageNavigation title="404-error" />
      <Container
        overflow="hidden"
        position="relative"
        fd="column"
        ai="center"
        jc="center"
        gutter
        $gtSm={{ flex: 1 }}
      >
        <Stack
          minWidth={500}
          position="absolute"
          width="100%"
          maxWidth={1200}
          aspectRatio={790 / 527}
        >
          <SolitoImage fill src={ImageSrc} alt="Student Saviour" />
        </Stack>
        <YStack mt={200} ai="center" jc="space-evenly" gap="$4" py="$4" maxWidth={364}>
          <SizableText tag="h1" textAlign="center" size="$10" fow="600">
            Error 404
          </SizableText>

          <Paragraph textAlign="center" fow="500" color="$overlay10">
            Oops, somethings went wrong. This Page cannot be found.
          </Paragraph>
          <SmartLink
            Component={Button}
            theme="primary"
            href={isWeb ? '/' : '/student-discount'}
            width="100%"
            aria-label="Go To Homepage"
            maxWidth={326}
          >
            Go To Homepage
          </SmartLink>
        </YStack>
      </Container>
    </MainLayout>
  )
}
